import React, { ReactNode } from "react"
import {
  PrismicDocument,
  PrismicLinkedDocument
} from "../prismic/PrismicModels"
import { NavigationContext } from "./NavigationContext"

export const NavigationProvider: React.FC<{
  navigation?: PrismicDocument
  navigationTrays?: PrismicDocument[]
  navigationLinkedDocuments?: PrismicLinkedDocument[]
  children?: ReactNode
}> = ({ navigation, navigationTrays, navigationLinkedDocuments, children }) => {
  return (
    <NavigationContext.Provider
      value={{
        navigation,
        navigationTrays,
        navigationLinkedDocuments
      }}
    >
      {children}
    </NavigationContext.Provider>
  )
}
